@import 'base/_variables';
@import 'base/_mixins';


.section, section,
section.wp-block-fp-section-container-block {
  .container {
    .inner-wrapper {
      justify-content: space-between;

      .block-title {
        width: 100%;
        max-width: 420px;
        margin-right: 50px;
      }
      .content-wrapper-title {
        width: 100%;
        max-width: 770px;
        margin-right: 60px;
      }
    }
  }
}

.intro {
  background-color: $bg;
  padding: 0 4%;

  .container {
    justify-content: flex-end;
    padding: 100px 0;

    .inner-wrapper {
      max-width: 880px;
      flex-wrap: wrap;
      margin-right: initial;
      justify-content: center;

      .block-title {
        max-width: 100%;
        margin-right: auto;
        @include font-size (24px);
        font-weight: $regular-weight;
      }

      .content-wrapper{
        max-width: 100%;

        p {
          @include font-size (32px);

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul li {
          @include font-size (24px);
        }
      }
    }
  }
}

.section.block.content-block.block-tekst.donkergrijs {
  background-color: $lichtGrijs;

  .container {
    padding: 100px 0;

    .inner-wrapper {
      .content-wrapper {
        h2 {
          text-align: center;

        }

        p {

        }
      }
    }
  }
}

.pagina {
  .container {
    h2 {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
      padding-bottom: 20px;
      margin-top: 50px;
      margin-right: 0;
      margin-left: auto;
    }

    ul {
      width: 100%;
      max-width: 880px;
      margin-right: 0;
      margin-left: auto;
      padding: 0 0 0 0;

      li {
        list-style: none;
      }
    }

    p {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-right: 0;
      margin-left: auto;

      &:first-child {
        width: 100%;
        max-width: 880px;
        //margin-right: 0;
        //margin-left: auto;
        @include font-size (24px);
        font-weight: $regular-weight;
        margin-bottom: 60px;
      }
    }
  }
}

.single-post {
  p {
    max-width: 770px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.landingspagina {
  padding-top: 145px;
}

.blogItems {
  .container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .blogItem {
      width: 100%;
      max-width: 31%;
      margin-bottom: 50px;
      display: flex;
      align-self: stretch;
      flex-wrap: wrap;
      background-color: rgba($lichtGrijs, .3);
      padding: 30px;
    }

    .pagination {
      width: 100%;
      max-width: 100%;
      margin-bottom: 50px;

      .nav-links {
        text-align: center;
      }
    }
  }
}
